import React from "react"

export const SystemIntegrationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 48">
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <path class="cls-1" d="M22,21c0,2.76,4.75,5,10.53,5S43,23.76,43,21" />
          <path
            class="cls-1"
            d="M43,21c0-2.76-4.72-5-10.5-5S22,18.24,22,21v7c0,2.76,4.72,5,10.5,5S43,30.76,43,28Z"
          />
          <path
            class="cls-1"
            d="M22,29v6c0,2.76,4.72,5,10.5,5S43,37.76,43,35V29"
          />
          <path
            class="cls-1"
            d="M22,36v6c0,2.76,4.72,5,10.5,5S43,44.76,43,42V36"
          />
          <path class="cls-1" d="M1,6c0,2.76,4.75,5,10.53,5S22,8.76,22,6" />
          <path
            class="cls-1"
            d="M22,6c0-2.76-4.72-5-10.5-5S1,3.24,1,6v7c0,2.76,4.72,5,10.5,5S22,15.76,22,13Z"
          />
          <path
            class="cls-1"
            d="M1,14v6c0,2.76,4.72,5,10.5,5a16.4,16.4,0,0,0,8.4-2"
          />
          <path
            class="cls-1"
            d="M1,21v6c0,2.76,4.72,5,10.5,5a16.4,16.4,0,0,0,8.4-2"
          />
          <path class="cls-1" d="M32,12V8a4,4,0,0,0-4-4H26" />
          <polyline class="cls-1" points="35 9 32 12 29 9" />
          <path class="cls-1" d="M13,36v4a4,4,0,0,0,4,4h2" />
          <polyline class="cls-1" points="10 39 13 36 16 39" />
        </g>
      </g>
    </svg>
  )
}

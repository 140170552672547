import React from "react"

export const WarehouseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 47">
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <path class="cls-1" d="M16,20c0,2.76,5.37,5,12,5s12-2.24,12-5" />
          <path
            class="cls-1"
            d="M28,15c-6.63,0-12,2.24-12,5v7c0,2.76,5.37,5,12,5s12-2.24,12-5V20C40,17.24,34.63,15,28,15Z"
          />
          <path class="cls-1" d="M16,27v7c0,2.76,5.37,5,12,5s12-2.24,12-5V27" />
          <path class="cls-1" d="M16,34v7c0,2.76,5.37,5,12,5s12-2.24,12-5V34" />
          <path class="cls-1" d="M1,6c0,2.76,5.37,5,12,5S25,8.76,25,6" />
          <path
            class="cls-1"
            d="M25,12V6c0-2.76-5.37-5-12-5S1,3.24,1,6v7c0,2.76,5.37,5,12,5"
          />
          <path class="cls-1" d="M1,13v7c0,2.76,5.37,5,12,5" />
          <path class="cls-1" d="M1,20v7c0,2.76,5.37,5,12,5" />
        </g>
      </g>
    </svg>
  )
}

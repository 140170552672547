import React from "react"
export const CloudIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 39.41">
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <path
            class="cls-1"
            d="M31,29h8a8,8,0,0,0,0-16,8.59,8.59,0,0,0-1,.07c0-.11,0-.21,0-.32a5.76,5.76,0,0,0-8-5.3A11,11,0,0,0,9,12c0,.34,0,.67.05,1H9A8,8,0,0,0,9,29h7"
          />
          <path class="cls-1" d="M27.59,10A5.64,5.64,0,0,1,30,7.45" />
          <path class="cls-1" d="M9,13a8.09,8.09,0,0,1,3,.58" />
          <line class="cls-1" x1="19" y1="19" x2="19" y2="37" />
          <line class="cls-1" x1="28" y1="21" x2="28" y2="38" />
          <polyline class="cls-1" points="15 23 19 19 23 23" />
          <polyline class="cls-1" points="24 34 28 38 32 34" />
        </g>
      </g>
    </svg>
  )
}

import React from "react"
import { ServiceCardGrid } from "./common/ServiceCardGrid"
import { ServiceCard } from "./common/ServiceCard"
import { Container } from "../../layout/surfaces/Container"
import { TailoredIcon } from "../../assets/svg/services/TailoredIcon"
import { CloudIcon } from "../../assets/svg/services/CloudIcon"
import { SystemIntegrationIcon } from "../../assets/svg/services/SystemIntegrationIcon"
import { RoadmapIcon } from "../../assets/svg/services/RoadmapIcon"
import { WarehouseIcon } from "../../assets/svg/services/WarehouseIcon"

export const ConsultingServices = () => {
  return (
    <Container>
      <ServiceCardGrid>
        <ServiceCard
          icon={<WarehouseIcon />}
          heading="Consulting Services"
          body="Just looking for advice? We're here to help.  We can look at your 
          current business requirements and systems and provide you with open and 
          honest advice about feasible software platforms that can benefit you."
        />
        <ServiceCard
          icon={<CloudIcon />}
          heading="Cloud Computing"
          body="We specialise in cloud computing platforms such as Microsoft Azure and 
          Amazon AWS, and offer a number of services for designing, planning and implementing cloud
          based applications. Access your applications anywhere you need to!"
        />
        <ServiceCard
          icon={<SystemIntegrationIcon />}
          heading="Business Systems Integrations and Analysis"
          body="If business systems are a mystery to you, we can undertake an analysis
          to see if efficiencies can be found through building integrations solutions between 
          platforms or simply replacing out-of-date legacy systems with new off-the-shelf platforms."
        />
        <ServiceCard
          icon={<RoadmapIcon />}
          heading="Technology Roadmaps"
          body="Successful, sustainable businesses must adapt as their industry changes and the needs of their customers and clients change. A technology roadmap will help determine which technologies to invest in and how to implement them. Planning for these changes can be a challenge we can help you develop a strategy to reach short- and long-term digital transformation goals."
        />
        <ServiceCard
          icon={<WarehouseIcon />}
          heading="Data Warehouse"
          body="Sharing accurate data across your organisation can be a challenge, but is possible 
          through a data exchange or data warehouse.  We can help you build a platform that is robust and secure that will 
          add value and power to your business intelligence."
        />
        <ServiceCard
          icon={<TailoredIcon />}
          heading="Software Development"
          body="We are a full stack development house with over ten years experience
            building applications across a wide variety of technologies. 
            We can build solutions tailored to specific and unique business requirements."
        />
      </ServiceCardGrid>
    </Container>
  )
}

import React from "react"

export const RoadmapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <polygon class="cls-1" points="6 8 1 12 6 16 20 16 20 8 6 8" />
          <polygon class="cls-1" points="42 13 26 13 26 21 42 21 47 17 42 13" />
          <polyline class="cls-1" points="29 21 29 47 20 47 20 34" />
          <path
            class="cls-1"
            d="M29,13V5.5A4.49,4.49,0,0,0,24.5,1h0A4.49,4.49,0,0,0,20,5.5V24"
          />
          <line class="cls-1" x1="15" y1="47" x2="34" y2="47" />
          <polygon class="cls-1" points="23 33 23 25 9 25 4 29 9 33 23 33" />
        </g>
      </g>
    </svg>
  )
}

import React from "react"

export const TailoredIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 46">
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
          <polygon
            class="cls-1"
            points="1 26 17 33 24 28 24 27 8 20 1 25 1 26"
          />
          <polygon
            class="cls-1"
            points="47 26 31 33 24 28 24 27 40 20 47 25 47 26"
          />
          <line class="cls-1" x1="24" y1="28" x2="24" y2="45" />
          <polyline class="cls-1" points="40 29 40 38 24 45 8 38 8 29" />
          <polyline class="cls-1" points="38 21 38 1 10 1 10 21" />
          <polyline class="cls-1" points="8 20 1 17 1 16 10 12" />
          <polyline class="cls-1" points="40 20 47 17 47 16 38 12" />
          <line class="cls-1" x1="13" y1="4" x2="15" y2="4" />
          <line class="cls-1" x1="17" y1="4" x2="19" y2="4" />
          <line class="cls-1" x1="10" y1="7" x2="38" y2="7" />
        </g>
      </g>
    </svg>
  )
}

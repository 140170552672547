import React from "react"
import { Heading } from "../../../components/typogarphy/Heading"
import { card, icon } from "./ServiceCard.module.scss"

export const ServiceCard = props => {
  return (
    <div className={card}>
      <div className={icon}>{props.icon}</div>
      <Heading variant="h4">{props.heading}</Heading>
      <div>{props.body}</div>
    </div>
  )
}

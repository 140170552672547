import React from "react"
import { PageHero } from "../components/hero/PageHero"
import { ConsultingServices } from "../content/services/ConsultingServices"

const ServicesPage = () => {
  return (
    <>
      <PageHero type="services" />
      <ConsultingServices />
    </>
  )
}

export default ServicesPage
